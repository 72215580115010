import React, { useState, useEffect } from "react";
import styled from "styled-components";
import photo from "./MaryLMillerDBA.jpg";
import waldenUniversityLogo from "./WaldenUniversityLogo.png";
import { ReactComponent as InfoIcon } from "./InfoIcon.svg";
import ReactGA from "react-ga";

export const App = () => {
  const [currentYear] = useState(new Date().getFullYear());

  // Initialize and Send Google Analytics
  useEffect(() => {
    ReactGA.initialize("UA-12678990-13");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Wrapper>
      <Photo alt="Mary L Miller Photo" />
      <ContentWrapper>
        <Logo src={waldenUniversityLogo} alt="Walden University Logo" />
        <Title>Employee Quit Intentions in Correctional Facilities</Title>
        <Text>
          Thank you for taking the time to participate in my study titled &ldquo;<Emphasis>Employee Quit Intentions in Correctional Facilities.</Emphasis>&rdquo; I really appreciate your participation by responding to the survey questions. Your feedback is invaluable as the data from this study may contribute to social change by providing supervisors with a better understanding of job satisfaction and employee turnover rates.
        </Text>
        <CalloutWrapper>
          <InfoIconStyled alt="Info Icon" /> Please note the results will be uploaded for your review no later than July 1, 2021.
        </CalloutWrapper>

        <SignatureWrapper>
          Warm Regards,
          <br />
          Mary Miller, MSA
          <br />
          Doctoral Candidate
          <br />
          Walden University
        </SignatureWrapper>
      </ContentWrapper>
      <FooterWrapper>
        &copy; {currentYear} Mary L Miller DBA
        <FooterLink eventLabel="http://www.3jpmedia.com" to="http://www.3jpmedia.com" target="_blank" title="Visit 3JPMedia.com">
          Powered by 3JPMedia
        </FooterLink>
      </FooterWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  padding: 0;
  margin: 0;
`;

const ContentWrapper = styled.main`
  width: 100%;
  max-width: 720px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 0 2rem;
`;

const Photo = styled.div`
  height: 70%;
  background-image: url(${photo});

  /* Center and scale the image nicely */
  background-position-x: center;
  background-position-y: 40%;
  background-repeat: no-repeat;
  background-size: cover;
`;

const Logo = styled.img`
  width: 100%;
  max-width: 500px;
`;

const Title = styled.h1`
  color: #0a3a5e;
  padding-left: 10px;
  border-left: 5px solid #3578ab;
  font-size: 4rem;
  line-height: 1.4;
`;

const Text = styled.p`
  color: #3c3c3c;
  font-size: 2rem;
  line-height: 3.2rem;
`;

const Emphasis = styled.span`
  color: #3578ab;
`;

const CalloutWrapper = styled.div`
  width: 100%;
  max-width: 576px;
  maring: 0 1rem;
  padding: 2rem;
  display: flex;
  flex-direction: row;
  background-color: #fdf5ea;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 2rem;
  font-style: italic;
  line-height: 3.2rem;
  border-radius: 8px;
`;

const InfoIconStyled = styled(InfoIcon)`
  fill: #0a3a5e;
  margin-right: 10px;
  width: 4rem;
  min-width: 4rem;
`;

const SignatureWrapper = styled.div`
  display: flex;
  align-self: flex-end;
  margin-top: 2rem;
  font-size: 1.8rem;
  line-height: 3rem;
  padding-right: 2rem;
`;

const FooterWrapper = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  padding: 2rem;
  font-size: 1.8rem;
  line-height: 1.4;
  color: #fdf5ea;
  background-color: #0a3a5e;
  font-family: "Source Sans Pro";
`;

const FooterLink = styled(ReactGA.OutboundLink)`
  margin-left: 3rem;
  color: #fdf5ea;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    color: #ffffff;
  }
`;
